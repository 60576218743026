import React from 'react'
import { ContactGtld, ApiContactGtldParams, Invalids } from '@/types'
import { prefectures, prefectures_en, FOREIN_COUNTRY } from '@/utils'
import Selector from '@/components/Parts/SelectorScroll'

type Props = {
  contacts: ApiContactGtldParams
  setContact: (contact: ApiContactGtldParams) => void
  setStateEn: (state: string) => void
}

function Prefecture({ contacts, setContact, setStateEn }: Props) {
  const selectedPrefecture = () =>
    contacts.PrefectureCode != null && contacts.PrefectureCode.length === 0
      ? undefined
      : prefectures[Number(contacts.PrefectureCode) - 1]

  const selectPrefecture = (value: string) => {
    const index = prefectures.findIndex((prefecture) => prefecture === value)

    setStateEn(prefectures_en[index])

    contacts.PrefectureCode = String(index + 1)
    setContact({ ...contacts })
  }
  return (
    <Selector
      name="Prefecture"
      required={false}
      selected={selectedPrefecture()}
      options={prefectures}
      onChange={selectPrefecture}
    />
  )
}

export default Prefecture
