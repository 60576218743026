import React, { useRef, useEffect } from 'react'
import { ContactGtld } from '@/types'
import useTranslation from '@/i18n'
import { useRightBar } from '@/hooks'
import ContactEditor from './ContactEditor'

type Props = {
  contact: ContactGtld
  load: () => Promise<void>
}

const Contact = ({ contact, load }: Props) => {
  const { d } = useTranslation()
  const { openRightBar, closeRightBar, isOpenRightBar } = useRightBar()
  const refIsOpenRightBar = useRef(isOpenRightBar)

  useEffect(() => {
    refIsOpenRightBar.current = isOpenRightBar
  }, [isOpenRightBar])

  const open = () => {
    closeRightBar()

    const intervalId = setInterval(() => {
      if (!refIsOpenRightBar.current) {
        clearInterval(intervalId)

        openRightBar(<ContactEditor reload={load} contact={contact} />)
      }
    }, 50)
  }

  return (
    <tr key={contact.ContactID}>
      <td className="px-6 py-4 break-words text-sm font-medium">
        <p
          className="cursor-pointer text-blue-500  hover:text-blue-300"
          onClick={open}
        >
          {contact.ContactID}
        </p>
      </td>
      <td className="px-6 py-4 text-sm text-gray-900 truncate whitespace-nowrap">
        {contact.ContactName}
      </td>
      <td className="px-6 py-4 text-sm text-gray-900 truncate whitespace-nowrap">
        {contact.ContactNameEn}
      </td>
      <td className="px-6 py-4 text-sm text-gray-900 truncate whitespace-nowrap">
        <ul>
          {contact.EMailAddress.map((email) => (
            <li>{email}</li>
          ))}
        </ul>
      </td>
      <td className="px-6 py-4 text-sm text-gray-900 truncate whitespace-nowrap">
        <ul>
          {contact.PhoneNumber.map((phone, index) => (
            <li key={index}>{phone}</li>
          ))}
        </ul>
      </td>
      <td className="px-6 py-4 text-sm text-gray-900  whitespace-nowrap">
        {d('ymd', new Date(contact.CreateTime))}
        <br />
        {d('hms', new Date(contact.CreateTime))}
      </td>
      <td className="px-6 py-4 text-sm text-gray-900  whitespace-nowrap">
        {d('ymd', new Date(contact.LastUpdateTime))}
        <br />
        {d('hms', new Date(contact.LastUpdateTime))}
      </td>
    </tr>
  )
}

export default Contact
