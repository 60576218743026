import React, { useEffect } from 'react'
import { get } from '@/api/contact-list/gtld'
import { ContactGtld } from '@/types'
import { useProgress, useRightBar, useSafeState } from '@/hooks'
import Reload from '@/components/Parts/Reload'
import Table from '@/components/Table'
import Header from '@/components/Contacts/Gtld/Header'
import ContactData from '@/components/Contacts/Gtld/ContactData'
import useTranslation from '@/i18n'

function Gtld() {
  const { t } = useTranslation()
  const [contacts, setContacts] = useSafeState<ContactGtld[]>([])
  const { start, stop } = useProgress()
  const { closeRightBar } = useRightBar()

  useEffect(() => {
    load()

    return () => {
      closeRightBar()
    }
  }, [])

  const load = () =>
    new Promise<void>((resolve, reject) => {
      get()
        .then((res) => {
          setContacts([...res])
          resolve()
        })
        .catch((err) => reject(err))
    })

  const reload = () => {
    start()
    load()
      .then((res) => {
        stop()
      })
      .catch((err) => {
        stop()
      })
  }

  return (
    <>
      <h1 className="text-2xl text-gray-500 font-medium">
        {t('Contact List')}
      </h1>
      <p className="text-gray-800 text-sm my-1">
        {t('ExplanationOfGtldContactList')}
      </p>
      <Reload load={reload} />
      <Table
        header={() => <Header />}
        listData={contacts}
        list={(data, index) => (
          <ContactData key={index} contact={data} load={load} />
        )}
      />
    </>
  )
}

export default Gtld
