import React, { useEffect } from 'react'
import { useProgress, useSafeState } from '@/hooks'
import { ApiContactGtldParams } from '@/types'
import { prefectures_en, FOREIN_COUNTRY } from '@/utils'
import countryList from 'country-list'
import ButtonToggle from '@/components/Parts/ButtonToggle'

type Props = {
  contacts: ApiContactGtldParams
  setContact: (contact: ApiContactGtldParams) => void
  setStateEn: (state: string) => void
}

function Domestic({ contacts, setContact, setStateEn }: Props) {
  const { inProgress } = useProgress()
  const [prevCountryCode, setPrevCountryCode] = useSafeState(
    contacts.CountryCode
  )
  const [prevPrefecture, setPrevPrefecture] = useSafeState(
    contacts.PrefectureCode
  )

  useEffect(() => {
    setPrevPrefecture(contacts.PrefectureCode)
  }, [])

  useEffect(() => {
    if (contacts.PrefectureCode !== FOREIN_COUNTRY)
      setStateEn(prefectures_en[Number(contacts.PrefectureCode) - 1])
  }, [contacts])

  const toggle = () => {
    if (!inProgress) {
      if (contacts.PrefectureCode != FOREIN_COUNTRY) {
        setPrevPrefecture(contacts.PrefectureCode)
        contacts.PrefectureCode = FOREIN_COUNTRY
        if (process.env.API_JPRS_GTLD) contacts.CountryCode = prevCountryCode
      } else {
        contacts.PrefectureCode =
          prevPrefecture === FOREIN_COUNTRY ? '1' : prevPrefecture
        if (process.env.API_JPRS_GTLD) {
          setPrevCountryCode(contacts.CountryCode)
          contacts.CountryCode = countryList.getCode('Japan')
        }

        setStateEn(prefectures_en[Number(contacts.PrefectureCode) - 1])
      }

      setContact({ ...contacts })
    }
  }

  return (
    <ButtonToggle
      label={'The address is in Japan'}
      toggle={contacts.PrefectureCode != FOREIN_COUNTRY}
      setToggle={toggle}
    />
  )
}

export default Domestic
