import { useProgress } from '@/hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  name: string
  selected?: string
  required: boolean
  options: string[]
  onChange: (value: string) => void
}

function Selector({ name, required, options, onChange, selected }: Props) {
  const [t] = useTranslation()
  const { inProgress } = useProgress()
  const change = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value)
  }

  return (
    <div className="mb-8 text-md">
      <div className="flex justify-items-center items-center mb-2">
        <p
          className={`${
            required && selected == null ? 'text-red-500' : 'text-gray-800'
          } mr-2 font-medium`}
        >
          {t(name)}
        </p>
        {/* {required && (
          <p className="bg-red-600 px-2 py-0 text-white m-0">{t('Required')}</p>
        )} */}
      </div>
      <div>
        <select
          disabled={inProgress}
          defaultValue={'DEFAULT'}
          className={`px-2 py-1 border-b ${
            required && selected == null
              ? 'border-red-500 text-red-500'
              : 'border-gray-500'
          } outline-none focus:outline-none bg-none w-36`}
          onChange={change}
        >
          <option disabled value="DEFAULT">
            {selected != null ? t(selected) : t('Select')}
          </option>
          {options.map((option, index) => (
            <option key={index} className="text-gray-800">
              {t(option)}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default Selector
