import React from 'react'
import useTranslation from '@/i18n'

interface Props {}

function Header(props: Props) {
  const { t, i18n } = useTranslation()
  const {} = props

  return (
    <tr>
      <th
        scope="col"
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        style={{ width: '300px' }}
      >
        {t('ID')}
      </th>
      <th
        scope="col"
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
      >
        {t('Contact name')}
      </th>
      <th
        scope="col"
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
      >
        {t('Contact name (En)')}
      </th>
      <th
        scope="col"
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
      >
        {t('Email')}
      </th>
      <th
        scope="col"
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
      >
        {t('Phone number')}
      </th>
      <th
        scope="col"
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
      >
        {t('Created at')} (UTC)
      </th>
      <th
        scope="col"
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
      >
        {t('Updated at')} (UTC)
      </th>
    </tr>
  )
}

export default Header
