import React from 'react'
import { ContactGtld, ApiContactGtldParams, Invalids } from '@/types'
import { prefectures, prefectures_en, FOREIN_COUNTRY } from '@/utils'
import Selector from '@/components/Parts/SelectorScroll'
import countryList from 'country-list'

type Props = {
  contacts: ApiContactGtldParams
  setContact: (contact: ApiContactGtldParams) => void
  setStateEn: (state: string) => void
  update: (key: string, value: string, res: boolean) => void
}

const foreignCountries = countryList.getNames().filter((c) => c !== 'Japan')

function Country({ contacts, setContact, setStateEn, update }: Props) {
  return (
    <Selector
      name="Country"
      required={true}
      selected={
        contacts.CountryCode != null && contacts.CountryCode !== 'JP'
          ? countryList.getName(contacts.CountryCode)
          : null
      }
      options={foreignCountries}
      onChange={(value) => {
        const countryCode = countryList.getCode(value)
        update('CountryCode', countryCode, true)
        setStateEn('')
      }} //Alaways true
    />
  )
}

export default Country
