import React from 'react'
import useTranslation from '@/i18n'

type Props = {
  header: () => JSX.Element
  listData: any[]
  list: (data: any, index: number) => JSX.Element
}

const Table = ({ header, listData, list }: Props) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b outline-none focus:outline-none border-gray-200 sm:rounded-lg">
            <table className="table-fixed min-w-full divide-y divide-gray-200 w-full">
              <thead className="bg-gray-50">{header()}</thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {listData.map((data, index) => list(data, index))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Table
