import React from 'react'
import useTranslation from '@/i18n'
import { useSafeState } from '@/hooks'

type Props = {
  label: string
  toggle: boolean
  setToggle: (toggle: boolean) => void
}

function ButtonToggle(props: Props) {
  const { t } = useTranslation()

  return (
    <>
      <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
        <input
          type="checkbox"
          name="toggle"
          id={props.label}
          checked={props.toggle}
          className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
          onChange={() => props.setToggle(!props.toggle)}
        />
        <label
          htmlFor={props.label}
          className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
        ></label>
      </div>
      <label
        htmlFor={props.label}
        className={`text-md ${
          props.toggle ? 'text-gray-900 font-medium' : 'text-gray-500'
        }`}
      >
        {t(props.label)}
      </label>
    </>
  )
}

export default ButtonToggle
