import React, { useEffect } from 'react'
import { ApiContactGtldParams, Invalids } from '@/types'
import UserInput from '@/components/Parts/UserInput'
import UserInputs from '@/components/Parts/UserInputs'
import { useSafeState } from '@/hooks'
import { prefectures_en, FOREIN_COUNTRY } from '@/utils'
import Prefecture from './Prefecture'
import Country from './Country'
import ToggleDomestic from './ToggleDomestic'

type Props = {
  invalids: Invalids
  contacts: ApiContactGtldParams
  setContact: (contact: ApiContactGtldParams) => void
}

function Inputs({ invalids, contacts, setContact }: Props) {
  const [stateEn, setStateEn] = useSafeState('')

  useEffect(() => {
    if (contacts.PrefectureCode !== FOREIN_COUNTRY)
      setStateEn(prefectures_en[Number(contacts.PrefectureCode) - 1])
  }, [contacts])

  const update = (key: string, value: string, res: boolean) => {
    contacts[key] = value
    setContact({ ...contacts })
  }

  const updateArray = (
    key: string,
    value: string,
    id: number,
    res: boolean
  ) => {
    contacts[key][id] = value
    setContact({ ...contacts })
  }

  const InputTable = {
    contactName: {
      name: 'Contact name',
      max: 120,
      pattern: '.{1,120}',
      invalid: invalids.ContactName,
      value: contacts.ContactName,
      required: true,
      onChange: (value, res) => update('ContactName', value, res),
    },
    contactNameEn: {
      name: 'Contact name (En)',
      max: 120,
      pattern: '.{1,120}',
      invalid: invalids.ContactNameEn,
      value: contacts.ContactNameEn,
      required: true,
      onChange: (value, res) => update('ContactNameEn', value, res),
    },
    webPage: {
      name: 'Web page',
      max: 256,
      pattern: '.{1,256}',
      invalid: invalids.WebPage,
      value: contacts.WebPage,
      required: false,
      onChange: (value, res) => update('WebPage', value, res),
    },
    email: {
      name: 'Email',
      max: 80,
      pattern: '.{1,80}',
      invalid: invalids.EMailAddress,
      value: contacts.EMailAddress!,
      required: false,
      onChange: (value, id, res) => updateArray('EMailAddress', value, id, res),
    },
    phone: {
      name: 'Phone number',
      max: 40,
      pattern: '.{1,40}',
      invalid: invalids.PhoneNumber,
      value: contacts.PhoneNumber!,
      required: false,
      onChange: (value, id, res) => updateArray('PhoneNumber', value, id, res),
    },
    fax: {
      name: 'Fax number',
      max: 40,
      pattern: '.{1,40}',
      invalid: invalids.FaxNumber,
      value: contacts.FaxNumber,
      required: false,
      onChange: (value, res) => update('FaxNumber', value, res),
    },
    postalCode: {
      name: 'Postal code',
      max: 8,
      pattern: '.{1,8}',
      invalid: invalids.PostalCode,
      value: contacts.PostalCode,
      required: false,
      onChange: (value, res) => update('PostalCode', value, res),
    },
    city: {
      name: 'City',
      max: 60,
      pattern: '.{1,60}',
      invalid: invalids.PostalAddress1,
      value: contacts.PostalAddress1,
      required: false,
      onChange: (value, res) => update('PostalAddress1', value, res),
    },
    address1: {
      name: 'Address 1',
      max: 60,
      pattern: '.{1,60}',
      invalid: invalids.PostalAddress2,
      value: contacts.PostalAddress2,
      required: false,
      onChange: (value, res) => update('PostalAddress2', value, res),
    },
    address2: {
      name: 'Address 2',
      max: 60,
      pattern: '.{1,60}',
      invalid: invalids.PostalAddress3,
      value: contacts.PostalAddress3,
      required: false,
      onChange: (value, res) => update('PostalAddress3', value, res),
    },
    stateEn: {
      name: 'State (English)',
      max: 60,
      pattern: '.{1,60}',
      invalid: invalids.PostalAddressEn1,
      value: contacts.PostalAddressEn1,
      required: false,
      onChange: (value, res) => update('PostalAddressEn1', value, res),
    },
    cityEn: {
      name: 'City (English)',
      max: 60,
      pattern: '.{1,60}',
      invalid: invalids.PostalAddressEn2,
      value: contacts.PostalAddressEn2,
      required: false,
      onChange: (value, res) => update('PostalAddressEn2', value, res),
    },
    streetEn: {
      name: 'Street (English)',
      max: 60,
      pattern: '.{1,60}',
      invalid: invalids.PostalAddressEn3,
      value: contacts.PostalAddressEn3,
      required: false,
      onChange: (value, res) => update('PostalAddressEn3', value, res),
    },
  }

  return (
    <>
      <UserInput {...InputTable.contactName} />
      <UserInput {...InputTable.contactNameEn} />
      <UserInput {...InputTable.webPage} />
      <UserInputs {...InputTable.email} />
      <UserInputs {...InputTable.phone} />
      <UserInput {...InputTable.fax} />
      <UserInput {...InputTable.postalCode} />

      <div className="my-4">
        <ToggleDomestic
          contacts={contacts}
          setContact={setContact}
          setStateEn={setStateEn}
        />
      </div>

      {contacts.PrefectureCode != FOREIN_COUNTRY && (
        <>
          <Prefecture
            contacts={contacts}
            setContact={setContact}
            setStateEn={setStateEn}
          />
          <UserInput {...InputTable.city} />
          <UserInput {...InputTable.address1} />
          <UserInput {...InputTable.address2} />

          <div className="mb-8">
            <p className="text-gray-900 text-md font-medium mr-2 mb-2">
              State (English)
            </p>
            <p className="text-gray-800 text-sm mr-2 px-2">{stateEn}</p>
          </div>
        </>
      )}

      {contacts.PrefectureCode === FOREIN_COUNTRY && (
        <>
          {process.env.API_JPRS_GTLD && (
            <Country
              contacts={contacts}
              setContact={setContact}
              setStateEn={setStateEn}
              update={update}
            />
          )}

          <UserInput {...InputTable.stateEn} />
        </>
      )}
      <UserInput {...InputTable.cityEn} />
      <UserInput {...InputTable.streetEn} />
    </>
  )
}

export default Inputs
